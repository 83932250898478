import React from "react";
import { Element } from "react-scroll";
import styled from "styled-components";
// Components
import { Container } from "react-bootstrap";
import { Title } from "./globalStyledComponents";
// Imports
import Me from "../images/me-bitmoji.png";
import SocialLinks from "./SocialLinks";

import {
  AiOutlineMail
} from "react-icons/ai";

const StyledSection = styled.section`
  min-height: 89vh;
  padding-top: var(--nav-height);
`;

export default function Contact() {
  return (
    <Element name={"Contact"} id="contact">
      <StyledSection className="d-flex flex-column justify-content-center">
        <Container className="d-flex">
          <Title style={{ marginTop: "30px" }}>
            <h1>Contact Me</h1>
            <div className="underline"></div>
          </Title>
        </Container>
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
            // width: "800px"
          }}>
          <div style={{ gap: "20px" }}>
            <h5 className="d-flex justify-content-center">Looking for a software engineer? Here I am!</h5>
            {/* <h6 className="d-flex justify-content-center">Shoot me an email or find me on socials!</h6> */}
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "40px",
              gap: "80px"
              }}>
              <img
                // className="rounded-circle"
                src={Me}
                style={{ width: "180px" }}
                alt="Me">
              </img>
              <div>
                <h4 style={{ textAlign: "center" }}><b>James Malone</b></h4>
                <h6><span><AiOutlineMail /></span> jmalone801@gmail.com</h6>
              </div>
            </div>
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "40px",
              marginBottom: "150px"
              }}>
              <SocialLinks />
            </div>
          </div>
        </Container>
      </StyledSection>
    </Element>
  );
}
