import React from "react";
// import { useSelector } from "react-redux";
// import { selectData } from "../pages/homeSlice";
import styled from "styled-components";
// Icons
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { BsFillCameraFill, BsInstagram } from "react-icons/bs";
import { HiOutlineNewspaper } from "react-icons/hi";

const StyledSocialLinks = styled.div`
.social-links {
    margin: 0px 10px 0px 10px;
  }
`;

export default function SocialLinks() {
  // const { html_url } = useSelector(selectData);

  return (
    <StyledSocialLinks>
      <a href="https://drive.google.com/file/d/1BhnB9dpAmrcsOlnOGF6xZmsdMO9LhESE/view?usp=sharing"
        aria-label="Check out my LinkedIn profile."
        className="link-icons social-links btn-floating"
        target="_blank"
        rel='noopener noreferrer'>
        <button
          class="btn btn-outline button-change float-img"
          style={{
            width: "150px",
            fontWeight: "600",
          }}
        ><HiOutlineNewspaper style={{ marginBottom: "5px" }} /> Resume</button>
      </a>


      <a href="https://linkedin.com/in/jamesemalone/"
        aria-label="Check out my LinkedIn profile."
        className="link-icons social-links btn-floating"
        target="_blank"
        rel='noopener noreferrer'
      >
        <button
          class="btn btn-outline button-change float-img"
          style={{
            width: "150px",
            fontWeight: "600",
            alignItems: "center"
          }}
        ><FaLinkedin style={{ marginBottom: "5px" }} /> LinkedIn
        </button>
      </a>
      {/* <a
        href="linkedin.com/in/jamesemalone/"
        aria-label="Check out my LinkedIn profile."
        className="link-icons social-links btn-floating"
        target="_blank"
        rel='noopener noreferrer'
      >
        <FaLinkedin />
      </a> */}

      <a href="https://github.com/jmalone801"
        aria-label="Check out my portfolio website."
        className="link-icons social-links btn-floating"
        target="_blank"
        rel='noopener noreferrer'
      >
        <button
          class="btn btn-outline button-change float-img"
          style={{
            width: "150px",
            fontWeight: "600",
            alignItems: "center"
          }}
        ><FaGithub style={{ marginBottom: "5px" }} /> GitHub
        </button>
      </a>
      {/* <a
        href="https://github.com/jmalone801"
        className="link-icons social-links"
        target="_blank"
        rel='noopener noreferrer'
      >
        <FaGithub />
      </a> */}

      <a href="https://jamesmalonephoto.com/"
        aria-label="Check out my photography portfolio."
        className="link-icons social-links btn-floating"
        target="_blank"
        rel='noopener noreferrer'
      >
        <button
          class="btn btn-outline button-change float-img"
          style={{
            width: "150px",
            fontWeight: "600",
            alignItems: "center"
          }}
        ><BsFillCameraFill style={{ marginBottom: "5px" }} /> Photography
        </button>
      </a>
      {/* <a
        href="https://jamesmalonephoto.com/"
        aria-label="Check out my photography portfolio."
        className="link-icons social-links"
        target="_blank"
        rel='noopener noreferrer'
      >
        <BsFillCameraFill />
      </a> */}

      <a href="https://www.instagram.com/domore.jm/?hl=en"
        aria-label="Check out my Instagram."
        className="link-icons social-links btn-floating"
        target="_blank"
        rel='noopener noreferrer'
      >
        <button
          class="btn btn-outline button-change float-img"
          style={{
            width: "150px",
            fontWeight: "600",
            alignItems: "center"
          }}
        ><BsInstagram style={{ marginBottom: "5px" }} /> Instagram
        </button>
      </a>
      {/* <a
        href="https://www.instagram.com/domore.jm/?hl=en"
        aria-label="Check out my Instagram."
        className="link-icons social-links"
        target="_blank"
        rel='noopener noreferrer'
      >
        <BsInstagram />
      </a> */}

    </StyledSocialLinks>
  );
}
