import React from 'react';
import styled from "styled-components";
// Components
import { Link } from "react-scroll";

const StyledFooter = styled.footer`
  min-height: var(--min-footer-height);
  background: var(--primary);

  a {
    color: #45413c;

    &:hover {
      color: #fbfdff;
    }
  }
`;

export default function Footer() {
  return (
    <StyledFooter className="d-flex align-items-center">
      <Link
        to={"Home"}
        activeClass="active "
        className="nav-link"
      >
        <h4 style={{ marginTop: "10px", marginLeft: "80px" }} className="title">JM</h4>
      </Link>
    </StyledFooter>
  );
}
