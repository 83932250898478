import React from "react";
// import { useAppContext } from "../appContext";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../pages/allProjectsSlice";
import { Element } from "react-scroll";
import styled from "styled-components";
import Card from '@mui/material/Card';

// Media
import Pet from "../images/pet-shelter.png";
import Car from "../images/car-collective.png";
// import Me from "../images/me3.png";


// Components
import { Col, Container, Row } from "react-bootstrap";
import { Title, Loading } from "./globalStyledComponents";

const StyledSection = styled.section``;

export default function Projects() {
  // const { theme } = useAppContext();
  const isLoading = useSelector(selectIsLoading);

  // const useStyles = {
  //     maxWidth: 310,
  //     transition: "transform 0.15s ease-in-out",
  //     "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
  //   }


  return (
    <Element name={"Projects"} id="projects">
      <StyledSection className="section">
        <Container>
          <Container className="d-flex">
            <Title style={{ marginTop: "30px" }}>
              <h1>Projects</h1>
              <div className="underline"></div>
            </Title>
          </Container>
          {isLoading && (
            <Container className="d-flex">
              <Loading />
            </Container>
          )}
          <Col>
            <Row xs={1} md={2} lg={3}
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "100px",
                alignItems: "center",
                marginTop: "60px"
              }}>

              {/* Project 1 */}
              <div style={{ width: "450px" }} className="float-img">
                <Card elevation={10} style={{ width: "450px", borderRadius: "25px" }}>
                  <a href="http://18.234.175.224/"
                    target="_blank"
                    rel='noopener noreferrer'>
                    <img
                      src={Pet}
                      style={{ width: "450px" }}
                      alt="Pet Shelter screen shot">
                    </img>
                  </a>
                </Card>
              </div>
              <div>
              {/* eslint-disable-next-line */}
                <h3 style={{ width: "450px" }}><b>Pet Shelter 🐾</b></h3>
                <p>Pet Shelter is an application where users can upload and adopt pets that are in need of a new home. Some features include full CRUD funcationality using an API, a functional search bar to find a pet by name or type, and image upload using Cloudinary.</p>
                {/* eslint-disable-next-line */}
                <h5><b>Tech Stack ⚙️</b></h5>
                <p>JavaScript (Node), React, Express, MongoDB, Mongoose, MaterialUI, Cloudinary, AWS (EC2), APIs, HTML, CSS</p>
                ⚡ <a href="http://18.234.175.224/"
                  target="_blank"
                  rel='noopener noreferrer'
                  className="photo-link"><b>Live Site</b></a><span> | </span>
                <a href="https://github.com/jmalone801/petShelter2.0"
                  target="_blank"
                  rel='noopener noreferrer'
                  className="photo-link"><b>Github</b></a> ⚡
              </div>


              {/* Project 2 */}
              <div>
                {/* eslint-disable-next-line */}
                <h3 style={{ width: "450px" }}><b>The Car Collective 🚗</b></h3>
                <p>The Car Collective is an application where users can buy and sell luxury vehicals. Some features include full CRUD functionality, log in and registration using Bcrypt for password hashing, and a relational database in MySQL for a structured One to Many relationship between users and cars.</p>
                {/* eslint-disable-next-line */}
                <h5><b>Tech Stack ⚙️</b></h5>
                <p>Python, Flask, MySQL, AWS (EC2), APIs, HTML, CSS, Bootstrap, Bcrypt, REGEX</p>
                ⚡ <a href="http://18.206.244.187/"
                  target="_blank"
                  rel='noopener noreferrer'
                  className="photo-link"><b>Live Site</b></a><span> | </span>
                <a href="https://github.com/jmalone801/dealership"
                  target="_blank"
                  rel='noopener noreferrer'
                  className="photo-link"><b>Github</b></a> ⚡
              </div>
              <div style={{ width: "450px" }} className="float-img">
                <Card elevation={10} style={{ width: "450px", borderRadius: "25px" }}>
                  <a href="http://18.206.244.187/"
                    target="_blank"
                    rel='noopener noreferrer'>
                    <img
                      src={Car}
                      style={{ width: "450px" }}
                      alt="Car Collective screen shot">
                    </img>
                  </a>
                </Card>
              </div>

            </Row>
          </Col>
        </Container>
      </StyledSection>
    </Element>
  );
}
