import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
/*
=============== 
Variables
===============
*/
:root {
  --primary-light: #3c88b3;
  /* Primary Color */
  --primary: #286588ed;
  --primary-dark: #3c88b3;
  --border: 1px solid #3c88b3;
  --transition: all 0.3s linear;
  --nav-height: 61px;
  --min-footer-height: 11vh;
}

/*
=============== 
Global Styles
===============
*/
body {
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.color};
}

.button-change {
  border: 2px solid ${({ theme }) => theme.color};
  color: ${({ theme }) => theme.color};
  margin-top: 5px;
  
  &:hover {
    color: var(--primary);
  }
}

.nav-link {
  cursor: pointer;
}

.photo-link {
  color: ${({ theme }) => theme.color};

  &:hover {
    color: var(--primary);
  }
}

.circle-change {
  border: 2px solid ${({ theme }) => theme.color};
}

.navbar {
  border-bottom: var(--border);
}

.link-icons {
  line-height: 0;
  font-size: 2.25rem;
  transition: var(--transition);
  color: ${({ theme }) => theme.color};

  &:hover {
        color: var(--primary);
      }
}

.section {
  min-height: 100vh;
  display: grid;
  place-items: center;
  padding: var(--nav-height) 0;

}

.title {
    font-family: "Oleo Script";
}

.float-img {
  transition: transform .5s; /* Animation */
}

.float-img:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}



.card {
  height: 30rem;
  border: var(--border);
  transition: all .2s ease-in-out;
  &:hover {
    transform: scale(1.03);
  }

  .card-img-top {
    height: 50%;
    object-fit: contain;
  }
}

@media screen and (min-width: 800px) {
  .link-icons {
    font-size: 3rem;
  }
  .form-group {
      max-width: 750px;
    }
}

@media screen and (min-width: 1367px) {
  .link-icons:hover {
    color: var(--primary);
  }
  }
`;

export default GlobalStyles;
