import React from 'react';
import { useSelector } from "react-redux";
import { selectData } from "../pages/homeSlice";
import { Link } from "react-scroll";
import styled from "styled-components";
// Icons
import { FaChevronCircleDown } from "react-icons/fa";
// Media
import Profile from "../images/me-hero.png";
import { Light, Dark } from "../data";
// Components
import { Col, Container, Row } from "react-bootstrap";
import { Spin } from "./globalStyledComponents";


const StyledHero = styled.header`
  position: relative;
  display: grid;
  place-items: center;
  max-width: 1920px;
  margin: 0 auto;
  min-height: calc(100vh - var(--nav-height));

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) =>
    theme.name === "light"
      ? "linear-gradient(135deg, var(--primary), var(--bs-light))"
      : "linear-gradient(135deg, var(--primary), var(--bs-dark))"};
    z-index: -2;
  }

  /* Overlay for contrast */
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) =>
    theme.name === "light"
      ? "rgba(255, 255, 255, 0.5)"
      : "rgba(0, 0, 0, 0.3)"};
    z-index: -1;
  }

  .down-container {
    height: 10rem;
  }

  @media (prefers-reduced-motion: no-preference) {
    .hero-img {
      animation: ${Spin} infinite 20s linear;
    }
  }

  @media screen and (min-width: 1180px) {
    &::before {
      background: ${({ theme }) =>
    theme.name === "light"
      ? `url(${Light}) top center fixed no-repeat`
      : `url(${Dark}) top center fixed no-repeat`};
      background-size: 100vw auto;
    }
  }

  @media screen and (min-width: 1367px) {
    &::before {
      background: ${({ theme }) =>
    theme.name === "light"
      ? `url(${Light}) center center fixed no-repeat`
      : `url(${Dark}) center center fixed no-repeat`};
      background-size: cover;
    }
  }
`;


export default function Hero() {
  const { name } = useSelector(selectData);

  return (
    <StyledHero>
      <Container
        style={{ marginRight: "90px", marginTop: "70px" }}>
        <Row className="align-items-center text-center">
          <Col className="d-none d-md-block">
            <img
              src={Profile}
              alt="Profile"
              loading="lazy"
              className="mx-auto rounded-circle circle-change"
              style={{ height: "250px" }}
            /><br></br>
            {/* <marquee style={{ width: "300px", color: "red" }}>Updated photo (that's not in a public restroom) coming soon!</marquee> */}
          </Col>
          <Col>
            <h1 className="display-3 title">{name}</h1>
            <div className="d-flex align-items-center justify-content-center">
              <h4 className="mb-2">Software Engineer</h4>
              {/* style={{ color: "#707071" }} */}
            </div>

            <a href="https://drive.google.com/file/d/1BhnB9dpAmrcsOlnOGF6xZmsdMO9LhESE/view?usp=sharing"
              target="_blank"
              rel='noopener noreferrer'>
              <button
                class="btn btn-outline button-change float-img"
                style={{
                  width: "180px",
                  fontWeight: "600",
                }}
              >Resume</button>
            </a>

          </Col>
        </Row>
        <Row className="align-items-end down-container">
          <Col className="m-4 text-center">
            <Link to={"About"} className="link-icons">
              <FaChevronCircleDown />
            </Link>
          </Col>
        </Row>
      </Container>
    </StyledHero>
  );
}
