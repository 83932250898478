import React from 'react';
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectData } from "../pages/homeSlice";
import { Element } from "react-scroll";
// Data
// import { moreInfo } from "../data";
// Components
import { Col, Container, Row } from "react-bootstrap";
import { Title } from "./globalStyledComponents";

const StyledAboutMe = styled.section`
  p {
    font-size: 1.25rem;
  }
  .img {
    width: 18rem;
    height: 18rem;
  }
  a {
    // text-decoration: none
  }
  a:hover {
    color: #366f8d
  }
`;

export default function AboutMe() {
  const { avatar_url, bio } = useSelector(selectData);

  return (
    <Element name={"About"} id="about">
      <StyledAboutMe className="section">
        <Container>
          <Container className="d-flex">
            <Title style={{ marginTop: "30px" }}>
              <h1>About Me</h1>
              <div className="underline"></div>
            </Title>
          </Container>
          <Row className="align-items-center mt-5">
            <Col className="d-flex flex-column">
              <Container>
                <p><b>{bio}</b></p>
                <p style={{ fontSize: "16px" }}>
                  Shortly after graduating college with a bachelors degree in marketing, I decided to I wanted to make the switch into something I have always been very interested in: coding. I made the leap to software engineering by attending a rigorous full time coding bootcamp, where I spent at least 10 hours per day building full stack applications, debugging code and solving problems. 
                </p>
                <p style={{ fontSize: "16px" }}>
                  My current work as a <span><a href="https://jamesmalonephoto.com/"
                                                target="_blank"
                                                rel='noopener noreferrer'
                                                className="photo-link"><b>freelance photographer</b></a></span> has created a solid foundation that has helped me hone my interpersonal and communication skills by working with clients, as well as sharpened my eye for detail and design. These experiences have made it very easy for me to work well in groups when pair programming or when designing an aesthetically pleasing website.
                </p>
                <p style={{ fontSize: "16px" }}>
                  While I wish I had opted to try becoming a software developer sooner, I wouldn't be who I am today if I hadn't followed my own path. I'm excited for all the opportunities that will come from hard work as well as all the skills I have yet to discover!
                </p>
                <p style={{ fontSize: "16px" }}>
                  Lastly, during my free time, I really enjoying spending time outdoors, riding and working on <span><a href="https://youtu.be/mp1zYluHGo0"
                                                target="_blank"
                                                rel='noopener noreferrer'
                                                className="photo-link"><b>my motorcycle,</b></a></span> snowboarding, watching F1, and spending time with my family and friends.
                </p>
              </Container>
            </Col>
            <Col className="d-none d-md-block text-center">
              <img
                src={avatar_url}
                alt="GitHub Avatar"
                loading="lazy"
                className="mx-auto rounded-circle circle-change"
                style={{ width: "20rem", height: "20rem" }}
              />
            </Col>
          </Row>
        </Container>
      </StyledAboutMe>
    </Element>
  );
}
